import { useTheme } from "@mui/material";
import Head from "next/head";

import { STAND } from "@/shared/constants";
import { useConfig } from "@/shared/hooks/use-config";

interface IPageHeadProps {
  title: string;
  seo?: {
    metaDescription?: string;
    keywords?: string;
    canonicalURL?: string;
    metaImage?: string;
    metaRobots?: string;
    metaTitle?: string;
    metaViewport?: string;
  };
}

export const PageHead = ({ title, seo }: IPageHeadProps) => {
  const appConfig = useConfig();
  const theme = useTheme();

  const pageTitle = seo?.metaTitle || `${appConfig?.title} - ${title}`;
  const siteName = `${appConfig?.title}.${appConfig?.countryCode}`;
  const projectTitle = appConfig?.title;
  const favicon = appConfig?.faviconUrl || "";

  const countryCodeLowercase = appConfig?.countryCode.toLowerCase();

  /**
   * TODO: Remove from config when every page on cms will be filled
   */
  const ogImage = seo?.metaImage || appConfig?.openGraphLogoUrl;

  const SEOCanonicalURL = seo?.canonicalURL ? (
    <link rel="canonical" href={seo.canonicalURL} />
  ) : null;

  const SEOKeywords = seo?.keywords ? (
    <meta name="keywords" content={seo.keywords} />
  ) : null;

  const SEODescription = seo?.metaDescription ? (
    <meta name="description" content={seo.metaDescription} />
  ) : null;

  const SEOImageOpenGraph = <meta property="og:image" content={ogImage} />;

  const SEOViewport = (
    <meta name="viewport" content="width=device-width, initial-scale=1" />
  );

  const getSEORobots = () => {
    if (appConfig?.stand === STAND.DEV) {
      return <meta name="robots" content="noindex,nofollow" />;
    }

    return seo?.metaRobots ? (
      <meta name="robots" content={seo.metaRobots} />
    ) : null;
  };

  const SEOTags = (
    <>
      {SEOKeywords}
      {SEODescription}
      {SEOCanonicalURL}
      {SEOImageOpenGraph}
      {SEOViewport}

      {getSEORobots()}
    </>
  );

  return (
    <Head>
      <title>{pageTitle}</title>
      <meta charSet="UTF-8" />
      <link
        rel="manifest"
        href={`/manifests/${countryCodeLowercase}/manifest.json`}
      />
      <link rel="icon" href={favicon} />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href={`${appConfig?.cdnUrl}/favicons/apple-touch-icon.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href={`${appConfig?.cdnUrl}/favicons/favicon-32x32.png`}
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href={`${appConfig?.cdnUrl}/favicons/favicon-16x16.png`}
      />
      <link
        rel="mask-icon"
        href={`${appConfig?.cdnUrl}/favicons/safari-pinned-tab.svg`}
        color={theme.palette.primary.main}
      />
      <meta name="application-name" content={projectTitle} />
      <meta name="theme-color" content="#ffffff" />
      <meta name="color-scheme" content="only light" />

      {SEOTags}

      {/* <meta property="og:title" content="" /> */}
      <meta property="og:site_name" content={siteName} />
    </Head>
  );
};
